import React, { useState } from "react";
import "../../styles/Header.css"
import {
  AppBar,
  Grid,
  Box,
  Toolbar,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Avatar,
  SwipeableDrawer,
  Popper,
  Fade,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import useStyles from "../../styles/userheader";
import { isBrowser, isMobile } from "react-device-detect";
import { ReactComponent as AddIcon } from "../assets/icons/Creating Post.svg";
import { ReactComponent as CompassIcon } from "../assets/icons/Explore.svg";
import { ReactComponent as MessageIcon } from "../assets/icons/Messenger.svg";
import { ReactComponent as CupIcon } from "../assets/icons/Cup.svg";
import { ReactComponent as BellIcon } from "../assets/icons/Notification.svg";
import { ReactComponent as LogoSvg } from "../assets/icons/K2_logo_v3.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/Search.svg";
import { AiOutlineMenu } from "react-icons/ai";
import CommonModal from "../common/CommonModal";
import { ReactComponent as HomeIcon } from "../assets/icons/Home.svg";
import { ReactComponent as LiveIcon } from "../assets/icons/LIVE.svg";
import { ReactComponent as MarketplaceColored } from "../assets/icons/MarketplaceColored.svg";
import { ReactComponent as ReelsIcon } from "../assets/icons/Reels.svg";
import { connect } from "react-redux";
import * as TabAction from "../../redux/action/TabAction";

import { Link, useLocation, useNavigate } from "react-router-dom";
function UserHeader(props) {
  const history = useNavigate();
  var is_admin_loged = localStorage.getItem("is-admin-loged");
  const classes = useStyles();
  const [metaMask, setMetaMask] = useState(false);
  const [drawerStatus, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const searchData = (e, value) => {
    console.log(e.terget.name);
    console.log(e.target.value);
  };

  const openModal = (event) => {
    // setMetaMask(true)
    setAnchorEl(event.currentTarget);
  };
  const closeModal = () => {
    setAnchorEl(null);
    // setMetaMask(false)
  };

  const adminlogout = () => {
    localStorage.removeItem('is-admin-loged');
    history("/admin");
  };

  const renderWeb = () => {
    return (
      <Grid container>
        <Grid item xs={3} display="flex" justifyContent="start">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            // aria-label="menu"
            className={classes.logo}
          >
            <LogoSvg />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              &nbsp;&nbsp;KNOCK KNOCK LIVE
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={5} sx={{ mt: 2 }} display="flex" justifyContent="start">
          <Paper
            component="form"
            sx={{ height: "35px" }}
            className={classes.searchInput}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, width: 500 }}
              placeholder="Search on Knock Knock Live"
              inputProps={{ "aria-label": "search google maps" }}
              id="search"
              name="search"
              onChange={(e) => searchData(e)}
            />
          </Paper>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={2}
          sx={{ mt: 1 }}
          display="flex"
          justifyContent="space-evenly"
        >
        {!is_admin_loged ? (
        // <> 
        //  <div>
        //     <button onClickCapture={adminlogout} type="button" className="btn btn-primary">Logout</button>
        //     </div>
        // </>):null}
          <>
          <AddIcon
            onClick={(e) => openModal(e)}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            style={{ cursor: "pointer" }}
          />
          <CompassIcon style={{ cursor: "pointer" }} />
          <MessageIcon style={{ cursor: "pointer" }} />
          <CupIcon style={{ cursor: "pointer" }} />
          <BellIcon style={{ cursor: "pointer" }} />
          <Avatar
            sx={{ borderRadious: "5px !important", mt: "7px" }}
            variant="square"
            style={{ cursor: "pointer" }}
            onClick={() => changeScreen(6)}
          >
            N
          </Avatar>
          </>
          ):null}

          {is_admin_loged ? (
        <> 
         <div>
            <button onClickCapture={adminlogout} type="button" className="btn btn-primary">Logout</button>
            </div>
        </>):null}
        </Grid>
        <Grid
          item
          xs={0}
          sx={{ mt: 1 }}
          display="flex"
          justifyContent="end"
        ></Grid>
      </Grid>
    );
  };
  const renderMobile = () => {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={2} alignItems="center">
          <IconButton
            aria-label="menu icon"
            color="primary"
            onClick={() => setDrawer(!drawerStatus)}
          >
            <AiOutlineMenu style={{ fontSize: "32px" }} />
          </IconButton>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="start">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            // aria-label="menu"
            className={classes.logo}
          >
            <LogoSvg />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const mobileMenu = () => {
    return (
      <Grid container sx={{ width: 200 }}>
        <Grid item xs={12}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            // aria-label="menu"
            className={classes.logo}
            sx={{ ml: 0 }}
          >
            <LogoSvg />
            <Typography variant="h6" sx={{ fontSize: "0.7rem" }}>
              &nbsp;KNOCK KNOCK LIVE
            </Typography>
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <HomeIcon size={30} />
          <Typography variant="h6">&nbsp;Home</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <ReelsIcon size={30} />
          <Typography variant="h6">&nbsp;Reels</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <LiveIcon size={30} />
          <Typography variant="h6">&nbsp;Live</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <MarketplaceColored size={30} />
          <Typography variant="h6">&nbsp;Market</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <MarketplaceColored size={40} />
          <Typography variant="h6">&nbsp;Profile</Typography>
        </Grid>
      </Grid>
    );
  };

  const changeScreen = (value) => {
    props.setSelectedTab(value);
    localStorage.setItem('currentTab',value)
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <CommonModal open={metaMask} closeModal={closeModal} />
      <AppBar position="static" className={classes.headerStyle}>
        <Toolbar sx={{}}>{!isMobile ? renderWeb() : renderMobile()}</Toolbar>
        <SwipeableDrawer
          anchor={"left"}
          open={drawerStatus}
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
          sx={{ width: "200px !important" }}
        >
          {mobileMenu()}
        </SwipeableDrawer>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={closeModal}
          onClick={closeModal}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0,
              ml: -2,
              background: "#1e2023e3",
              color: "#d570ff",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 30,
                width: 10,
                height: 10,
                bgcolor: "#312d2d",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <MenuItem sx={{ fontSize: "0.9rem" }} onClick={() => changeScreen(5)}>
            <Avatar /> Create NFT
          </MenuItem>
          <MenuItem sx={{ fontSize: "0.9rem" }}>
            <Avatar /> In-App NFT
          </MenuItem>
        </Menu>
      </AppBar>
    </Box>
  );
}
const mapStateToProps = (state) => {
  return {
    selectedTab: state.TabReducer.selectedTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTab: (token) => dispatch(TabAction.setSelectedTab(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);
