import React, { useEffect, useContext, useState } from "react";
import { Card, Row, Col, Container, Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import DateTimePicker from 'react-datetime-picker';
import UserHeader from "../navigation/userheader";
import axios from "axios";
import Swal from "sweetalert2";
// import { Web3Context } from "../marketPlace/providers/Web3Provider";
import MarketAbi from "../navigation/contracts/Marketplace.json";
import { ethers } from "ethers";
import Web3 from "web3";
import flatpickr from "flatpickr";
import { duration } from "@mui/material";

export default function Dashboard() {
  const [Listshow, setListshow] = useState(false);
  const [MakeAuction, setMakeAuction] = useState(false);
  const [Bidindex, setBidindex] = useState(false);
  const [Datetimeval, Setdatetimeval] = useState(new Date());
  const [Bidamount, SetBidamount] = useState();
  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      window.ethereum.request({ method: "eth_requestAccounts" });
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };
  const [playerData, setPlayerData] = useState([]);
  const [ShowBidList, SetShowBidList] = useState([]);
  useEffect(() => {
    loadWeb3();
    let userAddress = localStorage.getItem("wallet_address");
    const fetchData = async () => {
      let dom = [];
      const accounts = await window.web3.eth.getAccounts();
      console.log(accounts[0]);
      await axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/getNftByAddr?wallet_address=${accounts[0]}`
        )
        .then((res) => {
          let listed_person = res?.data?.data;
          console.log(listed_person);

          for (let i = 0; i < listed_person.length; i++) {
            let k = listed_person[i];
            // if(k.wallet_address === accounts[0]){
            dom.push(k);
            console.log(accounts[0]);
          }
          console.log(dom);
        });
      setPlayerData(dom.slice(0, 20));
    };
    fetchData();
  }, []);

  const bidORsale = (k) => {
    Swal.fire({
      title: "Want to sell in ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Marketplace`,
      denyButtonText: `Auction`,
    }).then((result) => {
      if (result.isConfirmed) {
        sell(k);
      } else if (result.isDenied) {
        // auctionCreate(k);
        setBidindex(k);
        setMakeAuction(true);
      }
    });
  };

  const auctionCreate = async (k) => {
    // Swal.fire({
    //   title: "Auction Bidding",
    //   html: `<input type="number" id="amount" class="swal2-input" placeholder="Amount">
    //   <input type="number" id="duration" class="swal2-input" placeholder="Duration">`,
    //   confirmButtonText: "Create Auction",
    //   focusConfirm: false,
    //   showCancelButton: true,
    //   preConfirm: () => {
    //     const amount = Swal.getPopup().querySelector("#amount").value;
    //     const duration = Swal.getPopup().querySelector("#duration").value;

    //     if (!amount || !duration) {
    //       Swal.showValidationMessage(`Please enter amount and duration`);
    //     }
    //     return { amount: amount, duration: duration };
    //   },
    // })
    // .then(async (result) => {

    let nft = playerData[k];
    console.log("Bidamount check", Bidamount);
    // console.log(result.value.amount);
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    // const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoBoysContract = new window.web3.eth.Contract(
      MarketAbi.abi,
      `${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`
    );
    console.log(cryptoBoysContract);
    // let price = result.value.amount;
    let price = Bidamount;
    let duration_ = Datetimeval; // need to convert in timestamp
    console.log("d", duration_);
    console.log("price", price);
    var date = new Date(duration_); // some mock date
    var auctionTimeInSeconds = Math.floor(date / 1000);
    console.log("current seconds", auctionTimeInSeconds);

    try {
      const priceInWei = ethers.utils.parseUnits(price, "ether");
      console.log(priceInWei);
      cryptoBoysContract.methods
        .createTokenAuction(
          `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`,
          nft.token_id,
          priceInWei,
          auctionTimeInSeconds
        )
        .send({ from: accounts[0] })
        .on("transactionHash", async (res) => {
          console.log(res);
          let postdata = {
            wallet_address: nft.wallet_address,
            transaction_id: nft.transaction_id,
            token_id: nft.token_id,
            amount: Bidamount,
            auction_duration: parseInt(Datetimeval),
            // auction_duration: auctionTimeInSeconds,
            auction_transaction_id: res,
          };

          console.log("post -", postdata);
          await axios.post(
            `${process.env.REACT_APP_Backend_url}/auth/create_auction`,
            postdata
          );
          swal("Success", "Auction Created " + res, "success").then(
            setTimeout(function () {
              window.location.reload();
            }, 2000)
          );
        });
    } catch (err) {
      console.log(err);
    }
    // });
  };

  const sell = (k) => {
    Swal.fire({
      title: "Market Sale",
      html: `<input type="number" id="price" class="swal2-input" placeholder="Price">`,
      confirmButtonText: "Sell",
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const price = Swal.getPopup().querySelector("#price").value;
        if (!price) {
          Swal.showValidationMessage(`Please enter the price`);
        }

        return { Price: price };
      },
    }).then(async (result) => {
      let nft = playerData[k];
      console.log(result.value.Price);
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      // const accounts = await window.web3.eth.getAccounts();

      console.log(accounts[0]);
      const cryptoBoysContract = new window.web3.eth.Contract(
        MarketAbi.abi,
        `${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`
      );
      console.log(cryptoBoysContract);
      let marketId = await cryptoBoysContract.methods
        .getCurrentMarketId()
        .call();
      let price = result.value.Price;
      console.log(price);
      try {
        const priceInWei = ethers.utils.parseUnits(price.toString(), "ether");
        console.log(priceInWei);
        cryptoBoysContract.methods
          .createMarketItem(
            `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`,
            nft.token_id,
            priceInWei
          )
          .send({ from: accounts[0] })
          .on("transactionHash", async (res) => {
            console.log(res);

            let marketId = await cryptoBoysContract.methods
              .getCurrentMarketId()
              .call();
            let postdata = {
              wallet_address: nft.wallet_address,
              transaction_id: nft.transaction_id,
              amount: result.value.Price,
              marketId: parseInt(marketId) + 1,
              sell_transaction_id: res,
            };
            await axios.post(
              `${process.env.REACT_APP_Backend_url}/auth/sellNft`,
              postdata
            );
            swal("Success", "Listed in Marketplace " + res, "success").then(
              setTimeout(function () {
                window.location.reload();
              }, 2000)
            );
          });
      } catch (err) {
        console.log(err);
      }
    });
  };

  const auctionCancel = async (k) => {
    let nft = playerData[k];
    console.log(nft);

    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoBoysContract = new window.web3.eth.Contract(
      MarketAbi.abi,
      `${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`
    );
    console.log(cryptoBoysContract, nft.token_id);
    console.log(cryptoBoysContract, "nft -", nft);

    try {
      cryptoBoysContract.methods.cancelAuction(
        `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`,
        nft.token_id
      )
        .send({ from: accounts[0] })
        .on("transactionHash", async (res) => {
          console.log(res);

          let postdata = {
            wallet_address: nft.wallet_address,
            tokenid: nft.token_id,
            transaction_id: nft.transaction_id,
            cancel_auction_transaction_id: res,
          };
          await axios.post(
            `${process.env.REACT_APP_Backend_url}/auth/cancel_auction`,
            postdata
          );

        });
    } catch (err) {
      console.log(err);
    }
  };


  const claimBid = async (k) => {

    // var d=new Date().now();
    // console.log("current tim in ms - ",d);

    let nft = playerData[k];
    console.log("nft", nft);
    console.log("nft aution time", nft.auction_duration);

    await loadWeb3();
    const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoBoysContract = new window.web3.eth.Contract(
      MarketAbi.abi,
      `${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`
    );
    console.log(cryptoBoysContract, nft.token_id);
    console.log(cryptoBoysContract, "nft -", nft);

    try {
      cryptoBoysContract.methods.executeSale(
        `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`,
        nft.token_id
      )
        .send({ from: accounts[0] })
        .on("transactionHash", async (res) => {
          console.log(res);

          let postdata = {
            wallet_address: nft.wallet_address,
            tokenid: nft.token_id,
            transaction_id: nft.transaction_id,
            max_bid: nft.max_bid,
            maxBidder_address: nft.maxBidder_address
          };
          await axios.post(
            `${process.env.REACT_APP_Backend_url}/auth/bid_sale`,
            postdata
          );

        });
    } catch (err) {
      console.log(err);
    }
  };


  const ShowAllbid = async (k) => {
    let nft = playerData[k];
    try {
      let getdata = {
        token_id: nft.token_id
      };
      await axios.get(
        `${process.env.REACT_APP_Backend_url}/auth/show_all_bids?token_id=${getdata.token_id}`, //${accounts[0]}
      ).then(res => {
        SetShowBidList(res.data?.data[0].bidders);
        setListshow(true);
        console.log(res.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  }

  const handleClose = () => setMakeAuction(false);
  // const handleShow = () => setMakeAuction(true);
  const handlelistClose = () => setListshow(false);
  // const handlelistShow = () => setListshow(true);

  useEffect(() => {
    console.log(Datetimeval.getTime());
  }, [Datetimeval]);
  const Bidamountchange = (event) => {
    SetBidamount(event.target.value)
    console.log(event.target.value)
  }
  return (
    <Container fluid>
      <UserHeader />
      <br />
      {<Modal className="connect-modal" show={MakeAuction} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title className="title">Auction Bidding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <input type="number" className="swal2-input" id="amount" value={Bidamount} onChange={Bidamountchange} placeholder="Amount" />
            <DateTimePicker closeWidgets={false} className="swal2-input" minDate={new Date()} onChange={Setdatetimeval} value={Datetimeval} />
          </div>
          <div className="d-flex flex-row justify-content-center">
            <Button onClick={() =>
              //sell(k)
              auctionCreate(Bidindex)
            }
              className="m-2"
            >Create auction</Button>
            <Button type="button"
              className="m-2"
              onClick={() =>
                //sell(k)
                handleClose()
              }
            >Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>}


      <Modal className="connect-modal" show={Listshow} onHide={handlelistClose} centered>
        <Modal.Header >
          <Modal.Title className="title">List of Bidding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="d-flex justify-content-center" lg={7}>
              <h5>Address</h5>
            </Col>
            <Col className="d-flex justify-content-center" lg={2}>
              <h5>Amount</h5>
            </Col>
            <Col className="d-flex justify-content-center" lg={2}>
              <h5>Status</h5>
            </Col>
            {
              ShowBidList?.map((bidding, index) => {
                return (
                  <>
                    <Col className="d-flex justify-content-center" lg={7}>
                      <span className="font12 d-flex align-items-center">{bidding.bidder_address}</span>
                    </Col>
                    <Col className="d-flex justify-content-center" lg={2}>
                      <span className="font14 d-flex align-items-center">{bidding.bid_amount}</span>
                    </Col>
                    <Col className="d-flex justify-content-center" lg={2}>
                      <Button onClick={() =>
                        claimBid(index)
                      }
                        className="bg-success"
                      >Accept</Button>
                    </Col>
                  </>
                )
              })}

          </Row>
        </Modal.Body>
      </Modal>


      <Row>
        {playerData.map((playerData, k) => (
          <Col key={k} xs={12} md={4} lg={3}>
            <Card className="my-2 width500">
              <Card.Img className="nftImgSize" src={playerData.image} />
              <Card.Body>
                <Card.Title>{playerData.nft_name}</Card.Title>
                <Card.Text className="address">
                  Trx:{" "}
                  <a
                    href={`https://mumbai.polygonscan.com/tx/${playerData.transaction_id}`}
                  >
                    {playerData.transaction_id}
                  </a>
                </Card.Text>
                <Card.Text>
                  <h6>Approval:</h6>{" "}
                  {playerData.status_of_approval === "Pending" ? (
                    <Card.Text className="YellowPending">
                      {playerData.status_of_approval}
                    </Card.Text>
                  ) : (
                    <Card.Text
                      className={
                        playerData.status_of_approval === "Accepted"
                          ? "greenAccepted"
                          : "redRejected"
                      }
                    >
                      {playerData.status_of_approval}
                    </Card.Text>
                  )}
                </Card.Text>
                <Card.Text>
                  {playerData.status_of_approval === "Accepted" ? (
                    playerData.status_of_nft === "Listed" ? (
                      <>Listed in Marketplace</>
                    ) : playerData.status_of_nft === "Sold" ? (
                      <>Sold</>
                    )
                      : playerData.status_of_nft === "Auction Created" ? (
                        <div className="d-flex flex-column">
                          Auction Created
                          <div className="d-flex flex-row">
                            <Button className="bg-success m-1" width="100px" onClick={() => ShowAllbid(k)} >Show all bid</Button>
                            <Button className="bg-danger m-1" width="100px" onClick={() => auctionCancel(k)}>Cancel</Button>
                          </div>
                        </div>
                      )
                        : (
                          <h6
                            className="YellowPending"
                            onClick={() =>
                              //sell(k)
                              bidORsale(k)
                            }
                          >
                            Sell Or bid
                          </h6>
                        )
                  ) : (
                    <></>
                  )}
                </Card.Text>
              </Card.Body>
              <br />
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}