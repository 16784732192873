import React from 'react'

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Carousel from 'react-material-ui-carousel'
import { isMobile } from "react-device-detect";
// import clement from "../../clement.jpg";
// import simon from "../../simon.jpg";
import AddIcon from "@mui/icons-material/Add";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MessageIcon from "@mui/icons-material/Message";
import ReplyIcon from "@mui/icons-material/Reply";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { Card } from "@mui/material";
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const handleClick = () => {
  console.info('You clicked the Chip.');
};

const handleDelete = () => {
  console.info('You clicked the delete icon.');
};

function HomeScreen() {
  const [expanded] = React.useState(false);

  // Story Layout Array
  const person = [
    { id: 1, image: '/assets/images/clement.jpg', username: "clement" },
    { id: 2, image: '/assets/images/simon.jpg', username: "simon" },
    { id: 3, image: '/assets/images/clement.jpg', username: "clement" },
    { id: 4, image: '/assets/images/simon.jpg', username: "simon" },
    { id: 5, image: '/assets/images/clement.jpg', username: "clement" },
  ];


  // sidebar Image Layout Array
  const sideImage = [
    { id: 1, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 2, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 3, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 4, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 5, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 6, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 7, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 8, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 9, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
    { id: 10, image: '/assets/images/small.png', text: "密宝", para: "Followed by Jason" },
  ]


  // Center Layout Array
  const mainImage = [
    { id: 1, title: "密宝", subheader: "11 mins ago", image: '/assets/images/small.png' },
    { id: 2, title: "密宝", subheader: "11 mins ago", image: '/assets/images/small.png' },
    // {id:3, title: "密宝", subheader:"11 mins ago", image: small},
  ]


  return (
    // <h4>
    //   Home
    // </h4>
    <Grid container spacing={2}>
      <Grid item xs={1}></Grid>
      <Grid item 
        lg={7}
        md={11}
        sm={11}
        xs={10}>
        <Grid container>
          <Grid item xs={12} display='flex' justifyContent={'space-evenly'} className="mob-pad-l130px overflow-auto">
            {person.map((data) =>
              <Avatar sx={{ width: 90, height: 90, border: '1px solid gray' }} variant='rounded' alt="Remy Sharp" src={`${data.image}`} />

            )}
          </Grid>
          <Grid item xs={12} className="mob-show hide">
            <Item
              sx={{
                boxShadow: "none",
                marginTop: 10,
                fontSize: 16,
                color: "#212330",
              }}
            >
              <strong className="recom_1">Recommended List</strong>
            </Item>
            <br></br>
            <Carousel>
              {sideImage.map((sid) => {
                return <div className="d-flex flex-row justify-content-center">
                  <div>
                    <Item sx={{ boxShadow: "none", marginLeft: -6, padding: 0 }}>
                      <img className="sid_img_1 mx-1" src={sid.image} width="80" />
                      <span className="text_1">{sid.text}</span>
                      <span className="para_1">{sid.para}</span>
                    </Item>
                  </div>
                  <div className="d-flex align-items-center mx-2" >
                    <Item sx={{ boxShadow: "none" }}
                      display="dlex"
                      justifyContent="center"
                      alignItems=" center"
                    >
                      <FavoriteIcon
                        sx={{ fontSize: 24, color: "#212330", float: "right" }}
                      // className= "width24px"
                      />
                      <span className="sid_icon_1">
                        <AddIcon />
                      </span>
                    </Item>
                  </div>
                </div>
              })}
            </Carousel>
          </Grid>

          <Grid item xs={12} className="my-2">
            {mainImage.map((main) => {
              return <>
                <Card
                  sx={{
                    maxWidth: 650,
                    height: 930,
                    marginLeft: 7,
                    borderRadius: 3,
                    backgroundColor: "#f2f2f2",
                    boxShadow: "none",
                  }}
                  className={isMobile ? "width-fitcon m-0" : ""}
                >
                  <CardHeader
                    avatar={
                      <Avatar sx={{ width: 60, height: 60, borderRadius: 3, border: "5px solid #dc74e2" }} aria-label="recipe" variant="square">
                        <img src={main.image} width="50" />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon sx={{ fontSize: 40, color: "#212330" }} />
                      </IconButton>
                    }
                    title={main.title}
                    subheader={main.subheader}
                  />

                  <CardMedia
                    component="img"
                    height="700"
                    src={main.image}
                    alt="image"
                  />

                  <br />

                  <Stack direction="row" spacing={1} sx={{ marginBottom: 1 }}>
                    <Chip sx={{ backgroundColor: "#f2f2f2", marginLeft: 2, fontSize: 18, fontWeight: "bold" }} icon={<FavoriteIcon style={{ color: "#bf51c4" }} />} label="507" />
                    <Chip style={{ backgroundColor: "#f2f2f2", marginLeft: "48%", color: "#212330", fontSize: 16 }} className="mob-margin15per" label="20 Comments" onClick={handleClick} />
                    <Chip sx={{ backgroundColor: "#f2f2f2", color: "#212330", fontSize: 16 }} label="50 Shares" onClick={handleClick} />
                  </Stack>


                  <CardActions disableSpacing sx={{ marginTop: -1 }}>
                    <IconButton aria-label="add to favorites">
                      <FavoriteIcon sx={{ fontSize: 40, color: "#212330" }} />
                    </IconButton>
                    <IconButton aria-label="share">
                      <MessageIcon sx={{ fontSize: 40, color: "#212330" }} />
                    </IconButton>
                    <IconButton aria-label="share">
                      <ReplyIcon sx={{ fontSize: 40, color: "#212330" }} />
                    </IconButton>

                    <Stack direction="row" spacing={1}>
                      <Chip sx={{ backgroundColor: "#f2f2f2", marginLeft: "130%", color: "#212330", fontSize: 16 }}
                        label="Tip"
                        onClick={handleClick}
                        className="mob-margin15px"
                        onDelete={handleDelete}
                        deleteIcon={<MonetizationOnIcon style={{ fontSize: 40, color: "#212330" }} />}
                      />
                      <Chip
                        sx={{ backgroundColor: "#f2f2f2", color: "#212330", fontSize: 16 }}
                        label="View"
                        onClick={handleClick}
                        onDelete={handleDelete}
                        deleteIcon={<GppGoodIcon style={{ fontSize: 40, color: "#212330" }} />}

                      />
                    </Stack>

                  </CardActions>
                </Card>
                <br />
              </>

            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item
        lg={3}
        md={10}
        sm={10}
        xs={10}
        className="mob-hide show"
      >
        <Item
          sx={{
            boxShadow: "none",
            marginTop: 10,
            fontSize: 16,
            color: "#212330",
          }}
        >
          <strong className="recom_1">Recommended List</strong> <span style={{ color: "#aeafb4", marginLeft: 40, position: "absolute", marginLeft: -33 }}>See All</span>
        </Item>
        <br></br>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {sideImage.map((sid) => {
            return <>
              <Grid item xs={1}>

              </Grid>
              <Grid item xs={7}>
                <Item sx={{ boxShadow: "none", marginLeft: -6, padding: 0 }}>
                  <img className="sid_img_1" src={sid.image} width="80" />
                  <span className="text_1">{sid.text}</span>
                  <span className="para_1">{sid.para}</span>
                </Item>
              </Grid>
              <Grid item xs={4} className="p-1"
                display="dlex"
                justifyContent="center"
                alignItems=" center"
              >
                <Item sx={{ boxShadow: "none" }}
                  display="dlex"
                  justifyContent="center"
                  alignItems=" center"
                >
                  <FavoriteIcon
                    sx={{ fontSize: 24, color: "#212330", float: "right" }}
                  // className= "width24px"
                  />
                  <span className="sid_icon_1">
                    <AddIcon />
                  </span>
                </Item>
              </Grid>

            </>

          })}
        </Grid>
      </Grid>
    </Grid>

  );
}

export default HomeScreen;
