import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FaceIcon from "@mui/icons-material/Face";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import axios from "axios";
import Swal from "sweetalert2";
import MarketAbi from "../navigation/contracts/Marketplace.json";
import { ethers } from "ethers";
import Web3 from "web3";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { duration } from "@material-ui/core";


const Nft = JSON.parse(localStorage.getItem("NftData"));
console.log("Nft details",Nft);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const loadWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    window.alert(
      "Non-Ethereum browser detected. You should consider trying MetaMask!"
    );
  }
};

const buyNft = async () => {
  // Swal.fire({
  //   title: "Sell NFT",
  //   text: "fkhfhsa  hsakldsfsdffsks sadsandflsaf",
  //   confirmButtonText: "Okk",
  //   focusConfirm: false,
  //   preConfirm: () => {
  //    window.location.href= "/dashboard"
  //   },
  // })
  await loadWeb3();
  const accounts = await window.web3.eth.getAccounts();

  console.log(accounts[0]);
  const cryptoBoysContract = new window.web3.eth.Contract(
    MarketAbi.abi,
    `${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`
  );
  console.log(cryptoBoysContract, Nft.market_id);

  try {
    let newPrice = Nft.price;
    const price1 = ethers.utils.parseUnits(newPrice.toString(), "ether");
    console.log(price1);
    cryptoBoysContract.methods
      .createMarketSale(
        `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`,
        Nft.market_id
      )
      .send({ from: accounts[0], value: price1 })
      .on("transactionHash", async (res) => {
        console.log(res);
        let postdata = {
          wallet_address: Nft.wallet_address,
          transaction_id: Nft.transaction_id,
          buyer_address: accounts[0],
          buy_transaction_id: res,
        };
        await axios.post(
          `${process.env.REACT_APP_Backend_url}/auth/buyNft`,
          postdata
        );
        // swal({title: "Success",text: "Listed in Marketplace " + res,type: "success"})
        // setTimeout(function () {
        //   window.location.reload();
        // }, 2000)
      });
  } catch (err) {
    console.log(err);
  }
};

const boxpro = [
  { id: 1, subtitle: "New Trait", title: "Brown Hair" },
  { id: 2, subtitle: "New Trait", title: "Black Eyes" },
  { id: 3, subtitle: "New Trait", title: "Brown Hair" },
  { id: 4, subtitle: "New Trait", title: "Black Eyes" },
  { id: 5, subtitle: "New Trait", title: "Brown Hair" },
  { id: 6, subtitle: "New Trait", title: "Black Eyes" },
  { id: 7, subtitle: "New Trait", title: "Brown Hair" },
  { id: 8, subtitle: "New Trait", title: "Black Eyes" },
];

const headings = [
  {
    id: 1,
    heading: "Unit Price",
  },
  {
    id: 2,
    heading: "USD Unit Price",
  },
  {
    id: 3,
    heading: "Quantity",
  },
  {
    id: 4,
    heading: "Expiration",
  },
  {
    id: 4,
    heading: "From",
  },
  {
    id: 6,
    heading: "",
  },
];
const offerHeadings = [
  {
    id: 1,
    heading: "Unit Price",
  },
  {
    id: 2,
    heading: "USD Unit Price",
  },
  {
    id: 3,
    heading: "Quantity",
  },
  {
    id: 4,
    heading: "Floor Difference",
  },
  {
    id: 5,
    heading: "Expiration",
  },
  {
    id: 6,
    heading: "From",
  },
];
const itemsHeadings = [
  {
    id: 1,
    heading: "Events",
  },
  {
    id: 2,
    heading: "Unit Price",
  },
  {
    id: 3,
    heading: "Quantity",
  },
  {
    id: 4,
    heading: "From ",
  },
  {
    id: 5,
    heading: "To",
  },
  {
    id: 6,
    heading: "Date",
  },
];
const details = [
  {
    id: 1,
    price: 100,
    unit: "$100.00",
    quan: 1,
    expr: "21 Hours",
    fm: "LightART",
    name: "Buy now",
  },
  {
    id: 2,
    price: 100,
    unit: "$100.00",
    quan: 1,
    expr: "21 Hours",
    fm: "LightART",
    name: "Buy now",
  },
  {
    id: 3,
    price: 100,
    unit: "$100.00",
    quan: 1,
    expr: "21 Hours",
    fm: "LightART",
    name: "Buy now",
  },
];

const offers = [
  {
    id: 1,
    price: 100,
    unit: "$100.00",
    quan: 1,
    floor: "10% below",
    expr: "21 Hours",
    fm: "LightART",
  },
  {
    id: 2,
    price: 100,
    unit: "$100.00",
    quan: 1,
    floor: "10% below",
    expr: "21 Hours",
    fm: "LightART",
  },
  {
    id: 3,
    price: 100,
    unit: "$100.00",
    quan: 1,
    floor: "10% below",
    expr: "21 Hours",
    fm: "LightART",
  },
];

const Items = [
  {
    id: 1,
    event: "Transfer",
    unit: "$100.00",
    quan: 1,
    from: "Mibao",
    to: "Jason",
    date: "An hour ago",
  },
  {
    id: 2,
    event: "Sale",
    unit: "$100.00",
    quan: 1,
    from: "Mibao",
    to: "Jason",
    date: "An hour ago",
  },
  {
    id: 3,
    event: "Transfer",
    unit: "$100.00",
    quan: 1,
    from: "Mibao",
    to: "Jason",
    date: "An hour ago",
  },
  {
    id: 4,
    event: "Sale",
    unit: "$100.00",
    quan: 1,
    from: "Mibao",
    to: "Jason",
    date: "An hour ago",
  },
];

const Collections = [
  { id: 1, image: "/assets/images/small.png" },
  { id: 2, image: "/assets/images/small.png" },
  { id: 3, image: "/assets/images/small.png" },
  { id: 4, image: "/assets/images/small.png" },
  { id: 5, image: "/assets/images/small.png" },
];

function ProductPreview() {
  const [_acc, setAcc] = React.useState();
  useEffect(() => {
    async function dataaa() {
      await loadWeb3();
      let accounts = await window.web3.eth.getAccounts();

      setAcc(accounts[0]);
      console.log(accounts[0], Nft.wallet_address);
    }
    dataaa();
  }, []);

  const sendNFT = async () => {
    const accounts = await window.web3.eth.getAccounts();
    // const accounts = await window.web3.eth.getAccounts();

    console.log(accounts[0]);
    const cryptoBoysContract = new window.web3.eth.Contract(
      MarketAbi.abi,
      `${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`
    );
    console.log(cryptoBoysContract);
    try {
      cryptoBoysContract.methods
        .bid(
          `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`,
          Nft.token_id
        )
        .send({ from: accounts[0] })
        .on("transactionHash", async (res) => {
          console.log(res);
          let postdata = {
            wallet_address: Nft.wallet_address,
            transaction_id: Nft.transaction_id,
            max_bid: 0.0022, // get from bidders array of nft last data details.
            maxBidder_address: "yet to assign", // get from bidders array of nft details.
            bidSale_transaction_id: res,
          };
          await axios.post(
            `${process.env.REACT_APP_Backend_url}/auth/place_bid`,
            postdata
          );
        });
    } catch (err) {
      console.log(err);
    }
  };

  const placeBid = () => {
    Swal.fire({
      title: "Place a Bid",
      html: `<input type="number" id="amount" class="swal2-input" placeholder="Amount">`,
      confirmButtonText: "Confirm",
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const amount = Swal.getPopup().querySelector("#amount").value;
        if (!amount) {
          Swal.showValidationMessage(`Please enter amount`);
        }
        return { amount: amount };
      },
    }).then(async (result) => {
      console.log(result.value.amount);
      const accounts = await window.web3.eth.getAccounts();
      // const accounts = await window.web3.eth.getAccounts();

      console.log(accounts[0]);
      const cryptoBoysContract = new window.web3.eth.Contract(
        MarketAbi.abi,
        `${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`
      );
      console.log(cryptoBoysContract);
      let price = result.value.amount;
      console.log("price -",price);
      try {
        const priceInWei = ethers.utils.parseUnits(price.toString(), "ether");
        console.log("priceWei -",priceInWei);
        cryptoBoysContract.methods
          .bid(
            `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`,
            Nft.token_id
          )
          .send({ from: accounts[0], value: priceInWei })
          .on("transactionHash", async (res) => {
            console.log(res);
            let postdata = {
              wallet_address: Nft.wallet_address,
              transaction_id: Nft.transaction_id,
              token_id:Nft.token_id,
              // nft_address:Nft.nft_address,
              bid_amount: result.value.amount,
              bidder_address: accounts[0],
              bid_transaction_id: res,
            };
            console.log("post",postdata);
            await axios.post(
              `${process.env.REACT_APP_Backend_url}/auth/place_bid`,
              postdata
            );
          });
      } catch (err) {
        console.log(err);
      }
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={7}>
              <Item sx={{ boxShadow: "none", marginTop: 10, marginLeft: -10 }} className="ml-0">
                <img src={Nft.image} width="580" height="640" />
              </Item>
            </Grid>
            <Grid item xs={12}  md={12} lg={5}>
              <Item sx={{ boxShadow: "none", marginTop: 8, marginLeft: -10 }} className="ml-0">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2} columns={16} className="product-dropdown">
                    <Grid item xs={8}>
                      <Item
                        sx={{
                          boxShadow: "none",
                          color: "#212330",
                          fontSize: 16,
                          marginLeft: 7,
                        }}
                      >
                        <h1 style={{ textAlign: "left" }}>{Nft.nft_name}</h1>
                      </Item>
                      <br />
                    </Grid>
                    <br />
                    <Grid item xs={8}>
                      <Item sx={{ boxShadow: "none", marginTop: 0 }}>
                        <Stack direction="row" spacing={1}>
                          <Chip
                            sx={{ backgroundColor: "#fff", marginLeft: 16 }}
                            icon={<FavoriteIcon style={{ color: "#bf51c4" }} />}
                            label="2.1K"
                          />
                        </Stack>
                      </Item>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      columns={12}
                      sx={{ marginTop: -6 }}
                    >
                      <Grid item xs={4}>
                        <Item
                          sx={{
                            boxShadow: "none",
                            marginTop: -1,
                            marginLeft: 4,
                          }}
                        >
                          <h4>Creator</h4>
                          <Chip
                            sx={{
                              backgroundColor: "#fff",
                              color: "#454ff9",
                              fontWeight: "bold",
                              marginLeft: 2,
                              marginTop: -1,
                            }}
                            avatar={
                              <Avatar
                                alt="Small"
                                src={"/assets/images/small.png"}
                              />
                            }
                            label={
                              <a
                                target="blank"
                                href={`https://mumbai.polygonscan.com/address/${Nft.creator_address}`}
                              >
                                {" "}
                                ..{Nft.creator_address.slice(-5)}{" "}
                              </a>
                            }
                          />
                        </Item>
                      </Grid>
                      <Grid item xs={4}>
                        <Item
                          sx={{
                            boxShadow: "none",
                            textAlign: "center",
                            marginTop: -1,
                          }}
                        >
                          <h4>Collection</h4>
                          <Chip
                            sx={{
                              backgroundColor: "#fff",
                              color: "#454ff9",
                              fontWeight: "bold",
                              marginLeft: 4,
                              marginTop: -1,
                            }}
                            avatar={<Avatar alt="Small" src={Nft.image} />}
                            label={
                              <a
                                target="blank"
                                href={`https://mumbai.polygonscan.com/address/0xf36362Dcac1f349f65820592FcE992FED3A024DC`}
                              >
                                {" "}
                                Knock Knock{" "}
                              </a>
                            }
                          />
                        </Item>
                      </Grid>
                      <Grid item xs={4}>
                        <Item
                          sx={{
                            boxShadow: "none",
                            marginLeft: 4,
                            marginTop: -1,
                          }}
                        >
                          <h4>Owner</h4>
                          <Chip
                            sx={{
                              backgroundColor: "#fff",
                              color: "#454ff9",
                              fontWeight: "bold",
                              marginTop: -1,
                            }}
                            avatar={
                              <Avatar
                                alt="Small"
                                src={"/assets/images/small.png"}
                              />
                            }
                            label={
                              <a
                                target="blank"
                                href={`https://mumbai.polygonscan.com/address/${Nft.wallet_address}`}
                              >
                                {" "}
                                ..{Nft.wallet_address.slice(-5)}{" "}
                              </a>
                            }
                          />
                        </Item>
                      </Grid>
                    </Grid>
                    <h3
                      style={{
                        color: "#212330",
                        marginTop: -1,
                        marginLeft: 70,
                      }}
                    >
                      Description
                    </h3>
                    <p
                      style={{
                        textAlign: "justify",
                        marginTop: -10,
                        paddingRight: 15,
                        color: "#000",
                        fontSize: 12,
                        marginLeft: 70,
                        maxHeight: 100,
                        overflow: "scroll",
                      }}
                    >
                      {Nft.description}
                    </p>
                    <Card
                      sx={{
                        // minWidth: 553,
                        marginTop: 2,
                        borderRadius: 4,
                        boxShadow: "none",
                        marginLeft: 5,
                      }}
                    >
                      <CardHeader
                        style={{
                          backgroundColor: "#4c4ff9",
                          textAlign: "left",
                          color: "#fff",
                        }}
                        subheader={"Sales Ends with - " +((new Date(Nft.auction_duration*1000)).toDateString())}


                      />
                      <CardContent sx={{ backgroundColor: "#f5f5f5" }}>
                        <Typography
                          style={{ textAlign: "left" }}
                          variant="body2"
                          color="text.secondary"
                        >
                          Current Price
                          <br /> <br />
                          <Chip
                            sx={{
                              backgroundColor: "#f5f5f5",
                              fontSize: 34,
                              fontWeight: "bold",
                            }}
                            avatar={
                              <Avatar
                                style={{ width: 40, height: 40 }}
                                alt="tlogo"
                                src={"/assets/images/tlogo.png"}
                              />
                            }
                            label={Nft.price}
                          />
                          <span
                            style={{ fontWeight: "lighter", color: "#212330" }}
                          >
                            ($1234.56 USD)
                          </span>
                        </Typography>
                        <br />
                        {Nft.wallet_address !== _acc ? (
                          <>
                            {Nft.type_of_listing === "Sale" ? (
                              <Button
                                style={{
                                  width: 480,
                                  paddingTop: 11,
                                  paddingBottom: 11,
                                  borderRadius: 12,
                                  marginTop: 5,
                                  backgroundColor: "#4c4ff9",
                                  fontWeight: "bold",
                                }}
                                variant="contained"
                                disableElevation
                                onClick={buyNft}
                              >
                                Buy now
                              </Button>
                            ) : 
                            (
                              <>
                              {Nft.type_of_listing === "Auction" && Nft.status_of_nft !== "Cancelled"  ?(
                                <>
                                   {" "}
                                <br />
                                <Grid container spacing={2} columns={16}>
                                  <Grid item xs={8}>
                                    <Item
                                      sx={{
                                        boxShadow: "none",
                                        backgroundColor: "#f5f5f5",
                                        marginTop: 4,
                                        textAlign: "left",
                                        marginLeft: 1,
                                      }}
                                    >
                                      <Button
                                        style={{
                                          width: 220,
                                          paddingTop: 11,
                                          paddingBottom: 11,
                                          borderRadius: 12,
                                          border: "1px solid #4c4ff9",
                                          color: "#4c4ff9",
                                          fontWeight: "bold",
                                        }}
                                        variant="outlined"
                                      >
                                        Buy with card
                                      </Button>
                                    </Item>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Item
                                      sx={{
                                        boxShadow: "none",
                                        backgroundColor: "#f5f5f5",
                                        marginTop: 4,
                                      }}
                                    >
                                      <Button
                                        style={{
                                          width: 220,
                                          paddingTop: 11,
                                          paddingBottom: 11,
                                          borderRadius: 12,
                                          border: "1px solid #4c4ff9",
                                          color: "#4c4ff9",
                                          fontWeight: "bold",
                                        }}
                                        onClick={placeBid}
                                        variant="outlined"
                                      >
                                        Make Offer
                                      </Button>
                                    </Item>
                                  </Grid>
                                </Grid>
                                </>
                              ):(
                                <>
                                <h3>Auction Cancelled</h3> 
                                </>
                              ) }
                              </>
                              )
                            }
                          </>
                        ) : (
                          <>
                             <Button
                              style={{
                                width: 480,
                                paddingTop: 11,
                                paddingBottom: 11,
                                borderRadius: 12,
                                marginTop: 5,
                                backgroundColor: "#4c4ff9",
                                fontWeight: "bold",
                              }}
                              variant="contained"
                              disableElevation
                              onClick={sendNFT}
                            >
                              Send NFT
                            </Button>
                          </>
                        )
                        }

                      </CardContent>
                    </Card>
                  </Grid>
                </Box>
              </Item>
            </Grid>

            <Accordion
              sx={{
                backgroundColor: "#4c4ff9",
                borderRadius: 4,
                boxShadow: "none",
                marginTop: 5,
                minWidth: "88vw",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}
              className = "product-dropdown"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#fff", fontSize: 30 }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: 0 }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  About
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                  maxHeight: 100,
                  overflowY: "scroll",
                  scrollMargin: "25px",
                }}
              >
                <Typography style={{ fontSize: 13, textAlign: "left" }}>
                  {Nft.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <br />
            <Accordion
              sx={{
                backgroundColor: "#4c4ff9",
                borderRadius: 4,
                boxShadow: "none",
                marginTop: 5,
                minWidth: "88vw",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}
              className = "product-dropdown"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#fff", fontSize: 30 }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: 0 }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  Properties
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <Typography style={{ fontSize: 13, textAlign: "left" }}>
                  <Grid container spacing={2} columns={16}>
                    {Nft.attributes.map((data) => {
                      return (
                        <>
                          <Grid item xs={2}>
                            <Item
                              style={{
                                width: 130,
                                height: 130,
                                marginTop: 10,
                                boxShadow: "none",
                                borderRadius: 13,
                              }}
                            >
                              <h5 style={{ color: "#000" }}>
                                {data.trait_type}
                              </h5>
                              <h3
                                style={{
                                  color: "#4c4ff9",
                                  fontWeight: "bold",
                                }}
                              >
                                {data.value}
                              </h3>
                            </Item>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <br />

            <Accordion
              sx={{
                backgroundColor: "#4c4ff9",
                borderRadius: 4,
                boxShadow: "none",
                marginTop: 5,
                minWidth: "88vw",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}
              className = "product-dropdown"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#fff", fontSize: 30 }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: 0 }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <Typography style={{ fontSize: 13, textAlign: "left" }}>
                  <h4>
                    Contract Address:{" "}
                    {`${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`}
                    <br />
                    Token ID: {Nft.token_id} <br />
                    Token Standard: ERC 721
                    <br />
                    Blockchain: Polygon Mumbai <br />
                    Metadata: <span className="break-word">{Nft.meta_data}</span>
                  </h4>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <br />

            <Accordion
              sx={{
                backgroundColor: "#4c4ff9",
                borderRadius: 4,
                boxShadow: "none",
                marginTop: 5,
                minWidth: "88vw",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}
              className = "product-dropdown"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#fff", fontSize: 30 }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: 0 }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  Price History
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <Typography style={{ fontSize: 13, textAlign: "left" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Item
                        sx={{
                          boxShadow: "none",
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        <Button
                          style={{
                            width: 200,
                            paddingTop: 11,
                            paddingBottom: 11,
                            borderRadius: 12,
                            marginTop: 5,
                            backgroundColor: "#4c4ff9",
                            fontWeight: "bold",
                            marginRight: 220,
                          }}
                          variant="contained"
                          disableElevation
                        >
                          All Time
                          <KeyboardArrowDownIcon
                            style={{
                              color: "#fff",
                              fontSize: 35,
                              marginLeft: 40,
                            }}
                          />
                        </Button>
                        <h4
                          style={{
                            textAlign: "left",
                            color: "#212330",
                            fontSize: 13,
                          }}
                        >
                          All Time Avg.Price
                        </h4>
                        <Chip
                          sx={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginRight: 50,
                          }}
                          avatar={
                            <Avatar
                              style={{ width: 20, height: 20 }}
                              alt="tlogo"
                              src={"/assets/images/tlogo.png"}
                            />
                          }
                          label="0.04"
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={8}>
                      <Item
                        sx={{
                          boxShadow: "none",
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        <img
                          src={"/assets/images/graph.png"}
                          width="760"
                          height="160"
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <br />

            <Accordion
              sx={{
                backgroundColor: "#4c4ff9",
                borderRadius: 4,
                boxShadow: "none",
                marginTop: 5,
                minWidth: "88vw",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}
              className = "product-dropdown"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#fff", fontSize: 30 }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: 0 }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  Listings
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <Grid container>
                  {headings.map((title) => (
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        {title.heading}
                      </Typography>
                    </Grid>
                  ))}

                  {details.map((data) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{ lineHeight: 0.9 }}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <Chip
                              sx={{
                                backgroundColor: "#f5f5f5",
                                fontSize: 15,

                                alignItems: "center",
                              }}
                              avatar={
                                <Avatar
                                  style={{
                                    width: 20,
                                    height: 20,
                                  }}
                                  alt="tlogo"
                                  src={"/assets/images/tlogo.png"}
                                />
                              }
                              label={data.price}
                            />
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 18,
                                margin: 0,
                              }}
                            >
                              {data.unit}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 18,
                              }}
                            >
                              {data.quan}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 10,
                              }}
                            >
                              {data.expr}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 0,
                              }}
                            >
                              {data.fm}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                            }}
                          >
                            <Button
                              style={{
                                border: "1px solid #4c4ff9",
                                color: "#4c4ff9",
                                borderRadius: 10,
                                paddingLeft: 24,
                                paddingRight: 24,
                              }}
                              variant="outlined"
                              size="small"
                            >
                              {data.name}
                            </Button>
                          </Item>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <br />

            <Accordion
              sx={{
                backgroundColor: "#4c4ff9",
                borderRadius: 4,
                boxShadow: "none",
                marginTop: 5,
                minWidth: "88vw",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}
              className = "product-dropdown"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#fff", fontSize: 30 }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: 0 }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  Offers
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <Grid container>
                  {offerHeadings.map((title) => (
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        {title.heading}
                      </Typography>
                    </Grid>
                  ))}

                  {offers.map((data) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{ lineHeight: 0.9 }}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <Chip
                              sx={{
                                backgroundColor: "#f5f5f5",
                                fontSize: 15,

                                alignItems: "center",
                              }}
                              avatar={
                                <Avatar
                                  style={{
                                    width: 20,
                                    height: 20,
                                  }}
                                  alt="tlogo"
                                  src={"/assets/images/tlogo.png"}
                                />
                              }
                              label={data.price}
                            />
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "center",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 18,
                                margin: 0,
                              }}
                            >
                              {data.unit}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 18,
                              }}
                            >
                              {data.quan}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 10,
                              }}
                            >
                              {data.floor}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 0,
                              }}
                            >
                              {data.expr}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 0,
                              }}
                            >
                              {data.fm}
                            </h4>
                          </Item>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <br />

            <Accordion
              sx={{
                backgroundColor: "#4c4ff9",
                borderRadius: 4,
                boxShadow: "none",
                marginTop: 5,
                minWidth: "88vw",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}
              className = "product-dropdown"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#fff", fontSize: 30 }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: 0 }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  Items Activity
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <Grid container>
                  {itemsHeadings.map((title) => (
                    <Grid item xs={2} display="flex" justifyContent={"center"}>
                      <Typography
                        variant="p"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        {title.heading}
                      </Typography>
                    </Grid>
                  ))}

                  {Items.map((data) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{ lineHeight: 0.9 }}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 18,
                                margin: 0,
                              }}
                            >
                              {data.event}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "center",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 18,
                                margin: 0,
                              }}
                            >
                              {data.unit}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 18,
                              }}
                            >
                              {data.quan}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                              }}
                            >
                              {data.from}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                              }}
                            >
                              {data.to}
                            </h4>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Item
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#f5f5f5",
                              borderRadius: 13,
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "lighter",
                                marginLeft: 0,
                              }}
                            >
                              {data.date}
                            </h4>
                          </Item>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <br />
            <Accordion
              sx={{
                backgroundColor: "#4c4ff9",
                borderRadius: 4,
                boxShadow: "none",
                marginTop: 5,
                minWidth: "88vw",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}
              className = "product-dropdown"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#fff", fontSize: 30 }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ minHeight: 0 }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  View more from this collection
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <Typography
                  style={{ maxWidth: 1107, marginLeft: 30, borderRadius: 10 }}
                >
                  <Grid container spacing={2} columns={15}>
                    {Collections.map((data) => {
                      return (
                        <>
                          <Grid item xs={3}>
                            <Item
                              style={{
                                boxShadow: "none",
                                backgroundColor: "#f5f5f5",
                                borderRadius: 13,
                              }}
                            >
                              <img src={data.image} width="200" height="200" />
                              <br />
                              <Grid container spacing={2} columns={6}>
                                <Grid item xs={3}>
                                  <Item
                                    sx={{
                                      boxShadow: "none",
                                      backgroundColor: "#f5f5f5",
                                    }}
                                  >
                                    <h6
                                      style={{
                                        marginTop: 2,
                                        textAlign: "initial",
                                        fontSize: 12,
                                      }}
                                    >
                                      Mibao
                                    </h6>
                                    <h6
                                      style={{
                                        marginTop: -27,
                                        textAlign: "initial",
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        color: "#000",
                                      }}
                                    >
                                      Mimi0530
                                    </h6>
                                    <h6
                                      style={{
                                        marginTop: -31,
                                        textAlign: "initial",
                                        fontSize: 12,
                                      }}
                                    >
                                      10/10
                                    </h6>
                                  </Item>
                                </Grid>
                                <Grid item xs={3}>
                                  <Item
                                    sx={{
                                      boxShadow: "none",
                                      backgroundColor: "#f5f5f5",
                                    }}
                                  >
                                    <Chip
                                      sx={{
                                        backgroundColor: "#f5f5f5",
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        marginRight: 50,
                                      }}
                                      avatar={
                                        <Avatar
                                          style={{ width: 20, height: 20 }}
                                          alt="tlogo"
                                          src={"/assets/images/tlogo.png"}
                                        />
                                      }
                                      label={Nft.price}
                                    />
                                    <h6
                                      style={{
                                        marginTop: 2,
                                        textAlign: "initial",
                                        fontSize: 12,
                                        marginLeft: 7,
                                      }}
                                    >
                                      X Days left
                                    </h6>
                                    <Chip
                                      sx={{
                                        backgroundColor: "#f5f5f5",
                                        fontSize: 12,
                                        marginTop: -5,
                                      }}
                                      icon={
                                        <FavoriteIcon
                                          style={{ color: "#000" }}
                                        />
                                      }
                                      label="2.1K"
                                    />
                                  </Item>
                                </Grid>
                              </Grid>
                            </Item>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
          <br />
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default ProductPreview;
