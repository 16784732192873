import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  NativeSelect,
  styled,
  CardMedia,
  CardActions,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { CATEGORY, MARKETDATA, SORTDATA, FILTERDATA } from "../../utils/data";
import { ReactComponent as ImageSelect } from "../assets/icons/ImageSelect.svg";
import { ReactComponent as DropdownICon } from "../assets/icons/Dropdown.svg";
import useStyles from "../../styles/userheader";
import CreteModal from "../common/CreateModal";
import { isMobile } from "react-device-detect";
import AddProperties from "../common/AddProperties";
import {
  BsGridFill,
  BsGrid,
  BsGrid3X3GapFill,
  BsGrid3X3Gap,
} from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { HiShoppingCart } from "react-icons/hi";
import { connect } from "react-redux";
import axios from "axios";

import * as TabAction from "../../redux/action/TabAction";
import FavoriteIcon from "@mui/icons-material/Favorite";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff0",
  ...theme.typography.body2,
  padding: 0,
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
function Market(props) {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("Select One");
  const [sortValue, setSortValue] = useState("Sort By");
  const [filterValue, setFilterValue] = useState("All Items");
  const [createModal, setCreateModal] = useState(false);
  const [propertiesModal, setPropertiesModal] = useState(false);
  const [listView, setListView] = useState(true);
  const arr = [1, 2, 3, 4, 5, 6];
  const [arrayItems, setArrayItems] = useState(arr);
  const [NftData, setNftData] = useState();
  const imagearr = [
    "Badge.png",
    "Image1.png",
    "Image2.png",
    "Image3.png",
    "Hydrangeas.jpg",
    "Image4.png",
    "Badge.png",
    "Image1.png",
    "Image2.png",
    "Image3.png",
    "Hydrangeas.jpg",
    "Image4.png",
  ];
  const handleChange = (e, value) => {
    console.log(e.target.value);
    setSelectedValue(e.target.value);
  };

  const createNFT = () => {
    setCreateModal(true);
  };

  const closeNFT = () => {
    setCreateModal(false);
  };
  const closeAddProperties = () => {
    setPropertiesModal(false);
  };
  const movetoProductPreview = (k) => {
    let data = JSON.stringify(playerData[k])
    setNftData(data)
    localStorage.setItem("NftData",data)
    localStorage.setItem("currentTab",7)
    page();
  };
  const page =()=>{
    console.log(NftData)
    console.log("stored");
    window.location.reload()
    props.setSelectedTab(7);
  }
  const getChildItems = (childItems) => {
    return childItems.map((item) => (
      <Accordion key={item.id} sx={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<DropdownICon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography sx={{ fontWeight: "bold" }}>{item.label}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {getChildDatas(item.datas)}
        </AccordionDetails>
      </Accordion>
    ));
  };
  const getChildDatas = (datas) => {
    return datas.map((item) => (
      <Accordion key={item.id} sx={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<DropdownICon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Typography>{item.label}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>{getDatas(item.data)}</AccordionDetails>
      </Accordion>
    ));
  };
  const getDatas = (item) => {
    return item?.map((data) => <Typography>{data.label}</Typography>);
  };

  const [playerData, setPlayerData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let dom = [];
      await axios
        .get(`${process.env.REACT_APP_Backend_url}/auth/getSellnfts`)
        .then((res) => {
          let listed_person = res?.data?.data;
          console.log("listed_person -",listed_person);
          for (let i = 0; i < listed_person.length; i++) {
            let k = listed_person[i];
            console.log("check -",k);

            // if ((k.type_of_listing === "Auction") || (k.type_of_listing === "Sale")) {
            if ((k.status_of_approval === "Accepted")) {

              dom.push(k);
            }
          }
          console.log("dom",dom);
        });

      setPlayerData(dom.slice(0, ));
    };
    fetchData();
  }, []);

  const storeData = (k) =>{
    localStorage.setItem("NftData", playerData[k])
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={4} md={4} lg={2} className="mob-hight-fit">
        {MARKETDATA.map((item) => (
          <Accordion key={item.id} sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<DropdownICon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="ml-1"
              sx={{ p: 0 }}
            >
              <Typography variant="h6" sx={{ color: "blue" }} className="ml-1">
                {item.label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }} className="ml-1">
              {getChildItems(item.nestedDropDowns)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      {/* // <Grid item xs={6} sm={4} md={4} lg={1}/> */}
      <Grid item xs={12} sm={8} md={8} lg={10} sx={{}}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={3}
            padding= "2px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="p" sx={{ fontWeight: "bold", ml: 2.5 }}>
              10,000,000 items
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={7} display="flex" justifyContent="end">
            <FormControl
              sx={{ width: "10rem", ml: 2, mr: 2 }}
              variant="standard"
            >
              {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel> */}
              <NativeSelect
                id="demo-customized-select-native"
                value={filterValue}
                onChange={(value) => handleChange(value)}
                input={<BootstrapInput />}
                style={{ background: "#f3f0f0" }}
              >
                {FILTERDATA.map((option) => (
                  <option value={10}> {option.title}</option>
                ))}
              </NativeSelect>
            </FormControl>
            <FormControl sx={{ width: "10rem" }} variant="standard">
              {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel> */}
              <NativeSelect
                id="demo-customized-select-native"
                value={sortValue}
                onChange={(value) => handleChange(value)}
                input={<BootstrapInput />}
                style={{ background: "#f3f0f0" }}
              >
                {SORTDATA.map((option) => (
                  <option value={10}> {option.title}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            display="flex"
            justifyContent="end"
            alignItems="center"
            sx={{ ml: -2.3 }}
          >
            {listView ? (
              <BsGridFill size={30} style={{ cursor: "pointer" }} />
            ) : (
              <BsGrid
                size={30}
                onClick={() => setListView(true)}
                style={{ cursor: "pointer" }}
              />
            )}
            {listView ? (
              <BsGrid3X3Gap
                size={30}
                onClick={() => setListView(false)}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <BsGrid3X3GapFill size={30} style={{ cursor: "pointer" }} />
            )}
          </Grid>
          {/* <Grid item xs={1} sm={0} md={0} lg={3} sx={{ mt: 1 }}></Grid> */}
          {playerData.map((playerData, k) =>
            listView ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                display="flex"
                justifyContent="end"
                sx={{ p: 1 }}
              >
                <Card sx={{ width: 375, borderRadius: "10px" }}>
                  <CardContent sx={{ background: "#c9baba4d", pb: 0 }}>
                    <div
                      className={classes.mypic}
                      style={{
                        background: `url(${playerData.image}) no-repeat`,
                      }}
                    ></div>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ p: 0, pt: 0, pl: 0 }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary={playerData.nft_name}
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        display="flex"
                        justifyContent={"end"}
                        sx={{ p: 0 }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon sx={{ minWidth: "22px" }}>
                              <Avatar
                                style={{ width: 20, height: 20 }}
                                alt="tlogo"
                                src={"/assets/images/tlogo.png"}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary={playerData.price}
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ paddingTop: "2px !important" }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary={
                                <Typography
                                  variant="p"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Mimi0530
                                </Typography>
                              }
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        display="flex"
                        justifyContent={"end"}
                        sx={{ paddingTop: "2px !important" }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            {/* <ListItemIcon sx={{ minWidth: '22px' }}>
                                                        <BsGridFill />
                                                    </ListItemIcon> */}
                            <ListItemText
                              sx={{ m: 0 }}
                              primary="x days left"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ paddingTop: "2px !important" }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary="10/10"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        display="flex"
                        justifyContent={"end"}
                        sx={{ paddingTop: "2px !important" }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon sx={{ minWidth: "22px" }}>
                              <FavoriteIcon style={{ color: "#0066ff" }} />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary="2.1k"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    sx={{ p: 0, backgroundColor: "#0000ffc4" }}
                  >
                    <Grid
                      container
                      sx={{ cursor: "pointer" }}
                      onClick={() => movetoProductPreview(k)}
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={11}
                        display="flex"
                        justifyContent={"start"}
                        sx={{
                          color: "#fff",
                          fontSize: "bold",
                          alignItems: "center",
                        }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ pt: 0 }}>
                            <ListItemText
                              primary="Buy now"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={1}
                        display="flex"
                        justifyContent={"end"}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem>
                            <ListItemIcon>
                              <HiShoppingCart size={30} color="#fff" />
                            </ListItemIcon>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    {/* <Button color='primary' variant='contained' endIcon={<HiShoppingCart />} sx={{ width: '400px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>Buy now</Button> */}
                  </CardActions>
                </Card>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={2}
                display="flex"
                justifyContent="center"
                sx={{ mt: 1, p: 1 }}
              >
                <Card
                  sx={{
                    width: 375,
                    boxShadow: "none",
                    borderRadius: "10px",
                    background: "#c9baba4d",
                  }}
                >
                  {/* <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                        R
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title="Shrimp and Chorizo Paella"
                                subheader="September 14, 2016"
                            /> */}
                  {/* <CardMedia
                                    component="img"
                                    height="200"

                                    image="/assets/images/sampleimg2.jpg"
                                    alt="Paella dish"
                                    style={{ width: '275px', objectFit: 'revert' }}
                                /> */}
                  <CardContent>
                    <div
                      className={classes.mypic2}
                      style={{
                        background: `url(${playerData.image}) no-repeat`,
                      }}
                    ></div>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ p: 0, pt: 0, pl: 0 }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary={playerData.name}
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        display="flex"
                        justifyContent={"end"}
                        sx={{ p: 0 }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon sx={{ minWidth: "22px" }}>
                              <Avatar
                                style={{ width: 20, height: 20 }}
                                alt="tlogo"
                                src={"/assets/images/tlogo.png"}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary="0.01"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        sx={{ paddingTop: "0px !important" }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary={
                                <Typography
                                  variant="p"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Mimi0530
                                </Typography>
                              }
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={8}
                        display="flex"
                        justifyContent={"end"}
                        sx={{ paddingTop: "0px !important" }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            {/* <ListItemIcon sx={{ minWidth: '22px' }}>
                                                        <BsGridFill />
                                                    </ListItemIcon> */}
                            <ListItemText
                              sx={{ m: 0 }}
                              primary="x days left"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ paddingTop: "0px !important" }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary="10/10"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        display="flex"
                        justifyContent={"end"}
                        sx={{ paddingTop: "0px !important" }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon sx={{ minWidth: "22px" }}>
                              <FavoriteIcon style={{ color: "#0066ff" }} />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary="2.1k"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    sx={{
                      pl: 0,
                      pr: 0,
                      pb: 0,
                      pt: 0,
                      backgroundColor: "#0000ffc4",
                    }}
                  >
                    <Grid
                      container
                      sx={{ cursor: "pointer" }}
                      onClick={() => movetoProductPreview()}
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={11}
                        display="flex"
                        justifyContent={"start"}
                        sx={{
                          color: "#fff",
                          fontSize: "bold",
                          alignItems: "center",
                        }}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ pt: 0, pb: 0 }}>
                            <ListItemText
                              primary="Buy now"
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={1}
                        display="flex"
                        justifyContent={"end"}
                      >
                        <List dense={true} sx={{ p: 0 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon>
                              <HiShoppingCart size={30} color="#fff" />
                            </ListItemIcon>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
    //<><Typography color='primary' variant='h5'>CREATE IN-APP NFT</Typography></>
  );
}
const mapStateToProps = (state) => {
  return {
    selectedTab: state.TabReducer.selectedTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTab: (token) => dispatch(TabAction.setSelectedTab(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Market);
