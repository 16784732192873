import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CATEGORY } from "../../utils/data";
import { ReactComponent as AddIcon } from "../assets/icons/Add.svg";
import useStyles from "../../styles/userheader";
import CreteModal from "../common/CreateModal";
import { isMobile } from "react-device-detect";
import AddProperties from "../common/AddProperties";
import MintNftAbi from "./contracts/NFTs.json";
import { ethers } from "ethers";
import { Buffer } from "buffer";
import Web3 from "web3";
import { create } from "ipfs-http-client";
import swal from "sweetalert";
import Container from "@material-ui/core/Container";
import RemoveIcon from "@material-ui/icons/Remove";
// import Icon from '@material-ui/core/Icon';
import { v4 as uuidv4 } from "uuid";
const ImageSelect = require("../assets/icons/ImageSelect.png");

const ipfs = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
});

export default function NFTCreate() {
  const history = useNavigate();
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("Select One");
  const [createModal, setCreateModal] = useState(false);
  const [propertiesModal, setPropertiesModal] = useState(false);
  const [imgHash, setImgHash] = useState("");
  const [metaHash, setMetaHash] = useState("");
  const [img, setImg] = useState();
  const [dataa, setDataa] = useState();
  const [isUpload, setisUpload] = useState(false);
  const handleChange = (e, value) => {
    console.log(e.target.value);
    setSelectedValue(e.target.value);
  };

  const Image = (event) => {
    setisUpload(true);
    let Files = event.target.files[0];

    var output = document.getElementById("output");
    output.src = URL.createObjectURL(event.target.files[0]);
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };
    const reader = new FileReader();
    reader.readAsArrayBuffer(Files);
    reader.onloadend = () => {
      setImg(Buffer(reader.result));
      console.log("buffer", img);
      console.log(Buffer(reader.result));
    };
    const reader2 = new FileReader();
    reader2.onloadend = function () {
      console.log("RESULT", reader2.result);
    };
    let dataa = reader2.readAsDataURL(Files);

    setDataa(dataa);
    console.log(dataa);
  };

  const uploadImage = async () => {
    try {
      const cid = await ipfs.add(img);

      let tokenURI = `https://ipfs.infura.io/ipfs/${cid.path}`;
      console.log({ tokenURI });

      return tokenURI;
    } catch (err) {
      console.log("errr---->", err);
    }
  };

  useEffect(() => {
    loadWeb3();
    btnhandler();
    console.log(1);
  }, []);

  const [data, setdata] = useState({
    address: "",
    Balance: null,
  });

  // Button handler button for handling a
  // request event for metamask
  const btnhandler = () => {
    // Asking if metamask is already present or not
    if (window.ethereum) {
      // res[0] for fetching a first wallet
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => accountChangeHandler(res[0]));
    } else {
      alert("install metamask extension!!");
    }
  };

  // getbalance function for getting a balance in
  // a right format with help of ethers
  const getbalance = (address) => {
    // Requesting balance method
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [address, "latest"],
      })
      .then((balance) => {
        // Setting balance
        setdata({
          Balance: ethers.utils.formatEther(balance),
        });
      });
  };

  // Function for getting handling all events
  const accountChangeHandler = (account) => {
    // Setting an address data
    setdata({
      address: account,
    });

    // Setting a balance
    getbalance(account);
  };

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  const createNFT = async () => {
    try {
      const name = document.getElementById("name").value;
      const description = document.getElementById("description").value;
      const externelLink = document.getElementById("externelLink").value;
      //  const imageInput = document.getElementById("imageInput").file;

      let imagUri = await uploadImage();
      console.log(name, description, externelLink, imagUri);

      let payloadJson = {
        name,
        description,
        // attributes: inputFields,
        image: imagUri,
      };
      console.log(inputFields);

      const cid = await ipfs.add(JSON.stringify(payloadJson));
      let metaData = `https://ipfs.infura.io/ipfs/${cid.path}`;
      const accounts = await window.web3.eth.getAccounts();
      console.log(accounts[0]);
      console.log(metaData, " metaData");
      const cryptoBoysContract = new window.web3.eth.Contract(
        MintNftAbi.abi,
        `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`
      );
      localStorage.setItem("wallet_address", accounts[0]);

      try {
        let result = await cryptoBoysContract.methods
          .mintToken(metaData)
          .send({ from: accounts[0],gas: 1000000 });
        if (result.status) {
          console.log(result);
          console.log(result.transactionHash);
          console.log(result.events.TokenMinted.returnValues.tokenId);
          let creator_address = await cryptoBoysContract.methods
            .getTokenCreatorById(parseInt(result.events.TokenMinted.returnValues.tokenId))
            .call();
          let postdata = {
            wallet_address: accounts[0],
            creator_address: creator_address,
            nft_name: name,
            description: description,
            meta_data: metaData,
            attributes: inputFields,
            image: imagUri,
            transaction_id: result.transactionHash,
            token_id: parseInt(result.events.TokenMinted.returnValues.tokenId),
          };
          console.log(postdata);
          let apiResponse = await axios.post(
            `${process.env.REACT_APP_Backend_url}/auth/mint`,
            postdata
          );
          swal(
            "Success",
            "Transaction Complete " + result.transactionHash,
            "success"
          ).then(
            setTimeout(function () {
              history("/dashboard");
            }, 3000)
          );

          console.log(result.transactionHash);
          console.log(apiResponse);
        }
      } catch (err) {
        swal("error", "Transaction InComplete " + err, "error");
      }
    } catch (err) {
      swal("error", "Transaction InComplete " + err, "error");
      console.log(err);
    }

    setCreateModal(false);
  };

  const [inputFields, setInputFields] = useState([
    { trait_type: "", value: "" },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("InputFields", inputFields);
  };

  const handleChangeInput = (index, event) => {
    const newInputFields = [...inputFields];
    newInputFields[index][event.target.name] = event.target.value;
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { trait_type: "", value: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const closeNFT = () => {
    setCreateModal(false);
  };
  const closeAddProperties = () => {
    setPropertiesModal(false);
  };
  return (
    <div>
      <Grid container spacing={2}>
        {/* <CreteModal open={createModal} closeModal={closeNFT} />
      <AddProperties open={propertiesModal} closeModal={closeAddProperties} /> */}
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
        >
          <Typography color="primary" variant="h5">
            CREATE IN-APP NFT
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
        >
          <Typography variant="p">Category</Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Paper
            component="form"
            sx={{ width: 600, boxShadow: "none", background: "#f3f0f0", width: "-webkit-fill-available"}}
          >
            <TextField
              onChange={(value) => handleChange(value)}
              variant="outlined"
              select
              name="gender"
              value={selectedValue}
              InputProps={
                {
                  // classes: {
                  //     input: classes.inputFields,
                  //     root: classes.inputRoot,
                  // },
                  // disableUnderline: true,
                }
              }
              fullWidth
            >
              {CATEGORY.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Card sx={{ maxWidth: 345, boxShadow: "none" }}>
            <CardHeader
              sx={{ p: 0 }}
              title="Media"
              subheader="Upload your image or video here"
            />
          </Card>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Card
            sx={{
              width: 900,
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f3f0f0",
              boxShadow: "none",
            }}
          >
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <ImageSelect onChange={(e) => Image(e, "personal_photo")} /> */}

                  <img
                    src={ImageSelect}
                    id="output"
                    alt="uploaded img"
                    width="300"
                    height="300"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                >
                  <Typography variant="h6">
                    Drag photos and videos here
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: "#8353fb" }}
                    className="width80per"
                  >
                    <input type="file" onChange={(event) => Image(event)} />
                    {/* <input type='submit' onClick={uploadImage} /> */}
                    Select from computer
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Typography variant="p" component="div">
            Name
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
        >
          <Paper component="form" sx={{width: "-webkit-fill-available"}} className={classes.searchInput}>
            <InputBase
              sx={isMobile ? { width: 250, p: 1 } : { width: 600, p: 1 }}
              placeholder=""
              inputProps={{ "aria-label": "search google maps" }}
              id="name"
              name="name"
              //   onChange={(e) => searchData(e)}
            />
          </Paper>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Typography variant="p" component="div">
            Description
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Paper
            component="form"
            sx={{ width: 900, minHeight: 100 , width: "-webkit-fill-available"}}
            className={classes.searchInput}
          >
            <InputBase
              multiline
              sx={isMobile ? { width: 250, p: 1 } : { width: 900, p: 1 }}
              placeholder=""
              inputProps={{ "aria-label": "search google maps" }}
              id="description"
              name="description"

              //   onChange={(e) => searchData(e)}
            />
          </Paper>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Card sx={{ maxWidth: 345, boxShadow: "none" }}>
            <CardHeader
              sx={{ p: 0 }}
              title="External link"
              subheader="Include a link here"
            />
          </Card>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Paper component="form" sx={{width: "-webkit-fill-available"}} className={classes.searchInput}>
            <InputBase
              sx={isMobile ? { width: 250, p: 1 } : { width: 600, p: 1 }}
              placeholder=""
              inputProps={{ "aria-label": "search google maps" }}
              id="externelLink"
              name="externelLink"
              //   onChange={(e) => searchData(e)}
            />
          </Paper>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid
          item
          xs={9}
          sx={{ display: "flex", justifyContent: "start", ml: 7, pt: 2 }}
          className="max-width120"
        >
          <Typography variant="p" component="div">
            Properties
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid className="margin72" xs={8}>
          <form className="width-fill" onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => (
              <div key={index}>
                <TextField
                  name="trait_type"
                  label="Trait Name"
                  variant="filled"
                  value={inputField.trait_type}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <TextField
                  name="value"
                  label="Value"
                  variant="filled"
                  value={inputField.value}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <IconButton
                  disabled={inputFields.length === 1}
                  onClick={() => handleRemoveFields(inputField.index)}
                >
                  <RemoveIcon />
                </IconButton>
                <IconButton onClick={handleAddFields}>
                  <AddIcon />
                </IconButton>
              </div>
            ))}
            <br></br>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              // endIcon={<>send</>}
              onClick={handleSubmit}
            >
              Add Attributes
            </Button>
          </form>
        </Grid>
        <Grid item xs={2}></Grid>
        {/* <Grid item xs={2}></Grid> */}
        <br />
        <Grid
          item
          xs={10}
          sx={{ display: "flex", justifyContent: "center", pt: 2 }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ backgroundColor: "#8353fb", width: 600 }}
            onClick={() => createNFT()}
            className="my-2 d-flex justify-content-center"
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </div>
    //<><Typography color='primary' variant='h5'>CREATE IN-APP NFT</Typography></>
  );
}
