import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useStyles from '../../styles/tabs';
import { ReactComponent as HomeIcon } from '../assets/icons/Home.svg';
import { ReactComponent as LiveIcon } from '../assets/icons/LIVE.svg';
import { ReactComponent as MarketplaceColored } from '../assets/icons/MarketplaceColored.svg';
import { ReactComponent as ReelsIcon } from '../assets/icons/Reels.svg';
import Market from '../market';
import Live from '../live';
import Reels from '../reels';
import HomeScreen from '../home';
import { isMobile } from 'react-device-detect';
import Product from '../market/Product';
import { connect } from 'react-redux';
import * as TabAction from '../../redux/action/TabAction'
import NFTCreate from './CreateNFT';
import Profile from '../profile';
import ProductPreview from '../market/Product';
import Footer from '../footer';

var currentTab;
function TabPanel(props) {
  console.log(props.selectedTab);
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pt: 2, width: window.innerWidth / 1.1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  // alert(index);
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function UserTabs(props) {
  console.log(props.selectedTab);
  const classes = useStyles();
  const [value, setValue] = React.useState(3);
  // useEffect(() => {
  //   console.log(props.selectedTab);
  // }, [])

  const handleChange = (event, newValue) => {
    props.setSelectedTab(newValue);
    localStorage.setItem('currentTab',newValue)
    // history.push('/my-profile')
  };
  const renderWeb = () => {
    return (
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', minHeight: window.innerHeight, height: 'auto', pr: 0 }}
      ><Tabs
        orientation="vertical"
        variant="scrollable"
        value={props.selectedTab}
        onChange={handleChange}
        aria-label="icon label tabs example width-50-tab"
        className={classes.tabs} 
        // inkBarStyle={{background: 'red'}}
      >
          <Tab label={'HOME'} className={ currentTab == 0 ? "head-active" : "" } icon={<HomeIcon />} {...a11yProps(0)} />
          <Tab label={'REELS'} className={ currentTab == 1 ? "head-active" : "" } icon={<ReelsIcon />} {...a11yProps(1)}> </Tab>
          <Tab label={'LIVE'} className={ currentTab == 2 ? "head-active" : "" } icon={<LiveIcon />} {...a11yProps(2)} ></Tab>
          <Tab label={'MARKET'} className={ currentTab == 3 ? "head-active" : "" } icon={<MarketplaceColored />} {...a11yProps(3)} ></Tab>
        </Tabs>
        {tabPanels()}
      </Box>)
  }
  const renderMobile = () => {
    return (
      <>
        <Tabs
          //orientation="vertical"
          // variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="icon label tabs example 2"
          className="width150 overflow-auto"
          
        >
          <Tab label={'HOME'} className={ currentTab == 0 ? "head-active" : "" } icon={<HomeIcon />} {...a11yProps(0)} />
          <Tab label={'REELS'} className={ currentTab == 1 ? "head-active" : "" } icon={<ReelsIcon />} {...a11yProps(1)}> </Tab>
          <Tab label={'LIVE'} className={ currentTab == 2 ? "head-active" : "" } icon={<LiveIcon />} {...a11yProps(2)} ></Tab>
          <Tab label={'MARKET'} className={ currentTab == 3 ? "head-active" : "" } icon={<MarketplaceColored />} {...a11yProps(3)} ></Tab>
        </Tabs>
        {tabPanels()}
      </>)
  }
  const tabPanels = () => {
    currentTab = localStorage.getItem('currentTab')!==null?parseInt(localStorage.getItem('currentTab')):props.selectedTab;
    return (
      <>
        <TabPanel value={currentTab} index={0}>
          <HomeScreen />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Reels />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Live />
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <Market />
        </TabPanel>
        <TabPanel value={currentTab} index={4}>
          <Product />
        </TabPanel>
        <TabPanel value={currentTab} index={5}>
          <NFTCreate />
        </TabPanel>
        <TabPanel value={currentTab} index={6}>
          <Profile />
        </TabPanel>
        <TabPanel value={currentTab} index={7}>
          <ProductPreview />
          <Footer/>
        </TabPanel>
      </>
    )
  }
  return (
    !isMobile ? renderWeb() : renderMobile()
  )
}
const mapStateToProps = (state) => {
  return {
    selectedTab: state.TabReducer.selectedTab
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTab: (token) =>
      dispatch(TabAction.setSelectedTab(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTabs);